.keyVisual {
  position: relative;
  padding-bottom: 88px;
  margin-bottom: 20px;

  @media (--more-than-tablet) {
    padding-bottom: 35px;
  }

  [class*="areaSelect"] {
    & select {
      background-color: var(--BG-Form-Dark);
      color: var(--TEXT-Base-Light-Primary);
    }
    &:after {
      background-image: url(/img/icon/arrow_under.svg);
    }
  }
}

.searchBtn {
  margin-top: 8px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  font-size: 15px;

  @media (--more-than-tablet) {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 8px;
    width: 143px;
  }
}

.heading {
  font-family: var(--font-noto);
  margin-bottom: 24px;
}

.logoDazn {
  margin: 16px 0 0;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.logoDazn:before {
  margin-right: 12px;
  content: "";
  width: 36px;
  height: 36px;
  background: transparent url(/img/logo_dazn.svg) no-repeat top left;
  background-size: cover;
}

.section {
  padding: 20px 0;

  @media (--more-than-tablet) {
    padding: 40px 0;
  }
}

.notification {
  margin-bottom: 20px;
}

.reservation {
  margin-bottom: 20px;
}

.banner {
  margin-bottom: 20px;
}

.news{
  padding-top: 20px;
  padding-bottom: 20px;

  @media (--more-than-tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .newsTitle{
    margin-bottom: 12px;
  }
}

.newsArticlesList{
  display: grid;
  row-gap: 12px;
}

.recommend {
  background-color:  var(--BG-Base-Dark-Secondary);

  @media (--more-than-tablet) {
    padding: 20px 0;
  }

  .heading {
    margin-bottom: 16px;
  }
}

.recommendList {
  @media (--more-than-tablet) {
    margin: 0 auto;
    overflow: visible;
    width: calc(91.46667% + 16px);
    max-width: 960px;
  }
}

.newsTitle,
.matchTitle,
.areaTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsLink,
.matchLink,
.areaLink {
  color: var(--TEXT-Link-Primary);
  font-size: 15px;
}

.match {
  padding-top: 0;
  padding-bottom: 52px;

  @media (--more-than-tablet) {
    padding-bottom: 40px;
  }
}

.matchTitle {
  margin-bottom: 20px;
}

.area {
  background-color:  var(--BG-Base-Dark-Secondary);
}

.areaTitle {
  margin-bottom: 16px;
}

.team {
  padding-top: 40px;
  padding-bottom: 28px;

  @media (--more-than-tablet) {
    padding-bottom: 40px;
  }
}

.shop {
  & :global(.l-main__innerWidth) {
    display: flex;
    background: url(/img/about/sp/img_005.jpg) no-repeat center center;
    background-size: cover;
    aspect-ratio: 16 / 9;
  }

  .shopContentWrapper {
    padding: 12px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 311px;

    @media (--more-than-tablet) {
      gap: 16px;
      width: 100%;
    }
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;

    @media (--more-than-tablet) {
      font-size: 18px;
    }
  }

  .text {
    text-align: center;
    line-height: 1.5;
    font-size: 14px;

    @media (--more-than-tablet) {
      font-size: 12px;
    }
  }

  .btn {
    height: 48px;
    width: 100%;
    background-color: transparent;
    font-size: 15px;
    letter-spacing: 0.1em;

    @media (--more-than-tablet) {
      height: 40px;
    }
  }
}

.daznList {
  margin-top: 16px;
}

.daznListLink {
  display: block;
  position: relative;
  padding-right: 10px;
  color:var(--TEXT-Base-Light-Primary);

  @media (--more-than-tablet) {
    padding: 8px 30px 8px 8px;
    background-color: var(--BG-Base-Dark-Basic);
    font-size: 15px;
    text-decoration: none;
  }

  &:before {
    @media (--more-than-tablet) {
      display: inline-block;
      margin-right: 8px;
      content: "";
      width: 36px;
      height: 36px;
      background: transparent url(/img/logo_dazn.svg) no-repeat top left;
      background-size: cover;
      vertical-align: middle;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: rotate(-90deg);
    width: 10px;
    height: 6px;
    margin-top: -4px;
    background: url(/img/icon_arrow_under.svg) no-repeat center center;
    background-size: 100% auto;

    @media (--more-than-tablet) {
      right: 16px;
    }
  }
}

.teamListWrapper {
  min-height: 100vh;
  &.hasList {
    min-height: auto;
  }
}

.container {
  @media (--more-than-tablet) {
    max-width: 944px;
  }
}

.column {
  border-top: 1px solid var(--STROKE-Base-Secondary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;

  @media (--more-than-tablet) {
    flex-direction: row;
    gap: 12px;
    width: 91.46667%;
    max-width: 944px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  & :global(.l-main__innerWidth) {
    @media (--more-than-tablet) {
      width: 100%;
    }
  }
}

.columnItem {
  @media (--more-than-tablet) {
    width: calc(33.3% - 8px);
  }
}

.note {
  margin-bottom: 40px;
  @media (--more-than-tablet) {
    font-size: 12px;
  }
}
