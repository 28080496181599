.modalWrapper{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;

  @media (--more-than-tablet) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.modalOverlay{
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modalInner{
  width: 100%;
  height: calc(100% - 69px);
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  @media (--more-than-tablet) {
    max-width: 764px;
    min-height: 655px;
    height: auto;
    position: relative;
  }
}
.modalClose{
  display: none;

  @media (--more-than-tablet) {
    display: block;
    padding: 0;
    width: 16px;
    height: 16px;
    appearance: none;
    outline: none;
    border: none;
    background: transparent url(/img/icon_close_white.svg) no-repeat center / contain;
    position: absolute;
    top: -28px;
    right: 0;
  }
}
.modalTopBar{
  margin: 0 auto;
  width: 60px;
  height: 4px;
  background: var(--STROKE-Base-Secondary);
  border-radius: 2px;
  position: absolute;
  right: 0;
  top: 8px;
  left: 0;
}
.modalContent{
  height: 100%;
  background: var(--BG-Base-Dark-Quaternary);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  overflow: hidden;
  transition: bottom 0.4s;
  --delta-y: 0px;
  transform: translate3d(0, var(--delta-y), 0);

  @media (--more-than-tablet) {
    height: auto;
    border-radius: 4px;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
  }
  40%,
  100% {
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
  }
  60%,
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60%,
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  60%,
  100% {
    opacity: 0;
  }
}

.slideIn {
  animation: slideIn 0.4s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}

.slideOut {
  animation: slideOut 0.3s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}

.fadeIn {
  animation: fadeOut 0.3s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}
.fadeOut {
  animation: fadeOut 0.3s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards;
}

.favoriteTeamHeader{
  padding: 12px 16px 0;
  background: var(--BG-Base-Dark-Tertiary);
  display: flex;

  @media (--more-than-tablet) {
    padding: 16px 20px 0;
  }
}
.favoriteTeamHeaderItem{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: var(--font-noto);
  color: var(--TEXT-Base-Light-Tertiary);
  cursor: pointer;

  span{
    padding: 16px 16px 0;
  }

  &::after{
    margin-top: 12px;
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;

    @media (--more-than-tablet) {
      margin-top: 16px;
    }
  }

  &.isActive{
    color: var(--TEXT-Tab-Primary);

    &::after{
      background-color: var(--STROKE-Tab-Primary);
    }
  }
}

.favoriteTeamTab{
  padding: 12px 16px;
  background: var(--BG-Base-Dark-Primary);
  display: flex;
  align-items: center;
  gap: 8px;

  @media (--more-than-tablet) {
    padding: 16px 20px;
  }
}

.favoriteTeamTabItem{
  padding: 8px 16px;
  appearance: none;
  outline: none;
  border-radius: 50px;
  border: none;
  line-height: 1;
  white-space: pre;
  background: var(--BG-Base-Dark-Secondary);

  span{
    font-family: var(--font-noto);
    font-size: 13px;
    color: var(--TEXT-Base-Light-Primary)
  }

  &.active{
    background: var(--BG-Tab-Dark-Secondary);

    span{
      color: var(--TEXT-Tab-Primary);
    }
  }
}

.favoriteTeamList{
  padding: 20px 16px 68px 16px;
  overflow-y: auto;
  height: calc(100% - 145px);

  @media (--more-than-tablet) {
    padding: 20px;
    height: auto;
  }
}
.copyright{
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  font-size: 12px;

  .logoDAZN{
    margin-right: 8px;
    width: 36px;
  }
}
.favoriteTeamLeadText{
  margin: 0 0 20px;
  font-family: var(--font-noto);
  color: var(--TEXT-Base-Light-Secondary);
}
.favoriteTeamListContent{ 
  display: flex;
  flex-wrap: wrap;
  gap: 30px 27px;
  justify-content: space-between;

  @media (--more-than-tablet) {
    gap: 20px 30px;
  }
}
.favoriteTeamListItem{
  width: 64px;
  text-align: center;
  cursor: pointer;

  &.selected{
    .favoriteTeamLogo{
      border: 3px solid var(--BG-Badge-Tertiary);
    }
  }

  @media (--more-than-tablet) {
    &:hover{
      transition: opacity 0.3s;
      opacity: 0.8;
    }
  }
}
.favoriteTeamLogo{
  padding: 0;
  appearance: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: var(--BG-Base-Dark-Tertiary);
  border-radius: 50px;
  position: relative;

  img{
    width: 48px;
  }
}
.favoriteTeamListItemText{
  margin: 4px -6px 0 -6px;
  font-family: var(--font-noto);
}
.favoriteTeamSelected{
  padding: 4px;
  display: block;
  width: 30px;
  background: var(--BG-Badge-Tertiary);
  border-radius: 12px;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  right: -15px;
  bottom: -6px;
}
.favoriteTeamButtonBlock{
  padding: 8px 16px;
  display: flex;
  gap: 12px;
  border-top: 1px solid var(--STROKE-Base-Secondary);
  background: var(--BG-Base-Dark-Basic);
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  @media (--more-than-tablet) {
    margin-top: 20px;
    padding: 0;
    justify-content: flex-end;
    gap: 24px;
    border: none;
    background-color: transparent;
    position: relative;
  }

  .button{
    padding: 14px 16px;
    width: 68%;
    line-height: 22px;
    letter-spacing: 2px;

    @media (--more-than-tablet) {
      width: auto;
      font-size: 14px;
    }

    p{
      line-height: 22px;
    }
  }
  .clearButton{
    width: 77px;
    padding: 1px 6px;

    @media (--more-than-tablet) {
      padding: 14px 16px;
      width: auto;
    }
  }

  .addButton {
    flex: 1;
    padding: 1px 6px;

    @media (--more-than-tablet) {
      flex: initial;
      padding: 14px 16px;
      width: auto;
    }
  }
}
