.title {
  max-width: 160px;
}

.newsArticlesList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 16px;
}


