.section {
  background-color: var(--BG-Base-Dark-Primary);
  padding: 20px 0;
}

.container {
  @media (--more-than-tablet) {
    max-width: 944px;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-noto);
  margin-bottom: 12px;
}

.link {
  color: var(--TEXT-Link-Primary);
  font-size: 15px;
}

.favoriteList {
  @media (--more-than-tablet) {
    margin: 0 auto;
    overflow: visible;
    width: calc(91.46667% + 16px);
    max-width: 960px;
  }
}
