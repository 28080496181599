.keyVisualSearch {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 0 8px;
  width: calc(91.46667% + 16px);
  max-width: 960px;
  margin: 0 auto;

  @media (--more-than-tablet) {
    display: flex;
    gap: 0 8px;
  }

  .list {
    display: flex;
    gap: 0 8px;
    padding: 16px;
    height: 50px;
    background-color: var(--BG-Form-Dark);
    border-radius: 4px;

    @media (--more-than-tablet) {
      flex: 1;
    }

    li {
      flex: 1;
    }
  }

  .btn {
    margin-top: 8px;
    height: 50px;
    padding: 16px 67px;
    background-color: var(--BG-Base-Colored-Primary);
    border-radius: 4px;
    text-align: center;

    @media (--more-than-tablet) {
      width: 160px;
      margin-top: 0;
      padding: 16px;
    }
  }
}

