.keyVisualImage {
  position: relative;

  @media (--more-than-tablet) {
    padding: 0 28%;
  }

  img {
    height: auto;
    aspect-ratio: 375 / 210;

    @media (--more-than-tablet) {
      max-height: 400px;
    }
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 960px;
  }
}
