.favoriteTeamShop{
  margin: 0 -4.26%;
  padding-top: 4px;

  @media (--more-than-tablet) {
    margin: 0 0 0 -8px;
    width: calc(100% + 16px);
    max-width: 960px;
  }
}

.container {
  margin-top: 20px;
}
