.indexKeyVisualImage {
  height: auto;
  aspect-ratio: 375 / 210;
  background-color: #333;

  &.disabled {
    pointer-events: none;
  }

  @media (--more-than-tablet) {
    max-height: 400px;
    object-fit: cover;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}

