.list {
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-noto);
}

.item {
  width: 31%;
  margin-right: 3.4%;

  @media (--more-than-tablet) {
    width: 11%;
    margin-right: 1.6%;
  }

  &:nth-child(n + 4) {
    margin-top: 20px;

    @media (--more-than-tablet) {
      margin-top: 0;
    }
  }

  &:nth-child(n + 9) {
    @media (--more-than-tablet) {
      margin-top: 20px;
    }
  }

  &:nth-child(3n) {
    margin-right: 0;

    @media (--more-than-tablet) {
      margin-right: 1.6%;
    }
  }

  &:nth-child(8n) {
    @media (--more-than-tablet) {
      margin-right: 0;
    }
  }
}

.link {
  display: block;
  text-decoration: none;
  color:var(--TEXT-Base-Light-Primary);
}

.teamImg {
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;

  @media (--more-than-tablet) {
    margin-bottom: 4px;
  }
}

.teamName {
  font-size: 12px;
  font-weight: bold;
  text-align: center;

  @media (--more-than-tablet) {
    line-height: 1.5;
  }
}
