.shopCampaignLabelList{
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media (--more-than-tablet) {
    margin-top: 4px;
  }
}

.shopCampaignLabelItem{
  padding: 4px 12px 2px;
  display: flex;
  align-items: center;
  border: 1px solid var(--STROKE-Tag-Primary);
  border-radius: 16px;
  font-size: 11px;
  color: var(--TEXT-Tag-Light);
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
}

.shopCampaignLabelIcon{
  margin-right: 2px;
  display: block;
  width: 16px;
  height: 16px;

  img{
    vertical-align: baseline;
  }
}
