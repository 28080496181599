.root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: var(--z-index-overlay-photo);
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  background: var(--color-black);

  @media (--more-than-tablet) {
    background-color: var(--color-overray);
  }
}

.closeOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;

  @media (--more-than-tablet) {
    position: relative;
    max-width: 856px;
    padding: 0 68px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 8px;
  left: 4px;
  border: none;
  width: 40px;
  height: 40px;
  background: url(/img/icon_close.svg) no-repeat center center;
  background-size: 22px;
  z-index: 10;

  @media (--more-than-tablet) {
    top: 40px;
    left: 40px;
    width: 28px;
    height: 28px;
    background-size: 28px;
    transition: opacity 0.3s;
  }
}

.close:hover {
  @media (--more-than-tablet) {
    opacity: 0.8;
  }
}

.text {
  margin-bottom: 20px;
  padding: 0 16px;
  font-size: 16px;
  color: #fff;

  @media (--more-than-tablet) {
    padding: 0;
    margin: 16px 0 0;
  }
}

.nav {
  position: fixed;
  right: 0;
  bottom: 60px;
  left: 0;

  @media (--more-than-tablet) {
    position: static;
  }
}

.arrows {
  position: fixed;
  right: 0;
  bottom: 40px;
  left: 0;

  @media (--more-than-tablet) {
    position: static;
  }
}

.arrowsInner {
  position: relative;
}

.arrow {
  position: absolute;
  top: -12px;
  width: 40px;
  height: 40px;
  border: none;
  background: url(/img/icon_arrow_wh_active.svg) no-repeat center center;
  background-size: 8px 14px;

  @media (--more-than-tablet) {
    top: 50%;
    width: 48px;
    height: 48px;
    background-color: var(--ICON-Button-Filled-Primary);
    background-size: 12px 21px;
    border-radius: 50%;
    margin-top: -48px;
    transition: opacity 0.3s;
  }
}

.arrow:hover {
  @media (--more-than-tablet) {
    opacity: 0.8;
  }
}

.arrow-prev {
  left: 4px;
  transform: rotate(180deg);

  @media (--more-than-tablet) {
    left: 0;
  }
}

.arrow-prev-disabled {
  background-image: url(/img/icon_arrow_wh_disabled.svg);
  transform: rotate(0deg);
}

.arrow-next {
  right: 4px;

  @media (--more-than-tablet) {
    right: 0;
  }
}

.arrow-next-disabled {
  background-image: url(/img/icon_arrow_wh_disabled.svg);
  transform: rotate(180deg);
}

.arrow-prev-disabled,
.arrow-next-disabled {
  pointer-events: none;
}

.pager {
  text-align: center;
  font-size: 16px;
  color: var(--TEXT-Base-Light-Primary);

  @media (--more-than-tablet) {
    margin-top: 16px;
  }
}
