.shopCard{
  flex: none;
  width: 240px;
  background-color: var(--BG-Base-Dark-Tertiary);
  border-radius: 8px;

  @media (--more-than-tablet) {
    width: auto;
  }
}

.fixedWidth{
  width: 240px;

  @media (--more-than-tablet) {
    width: 304px;
  }
}

.sliderCard{
  margin: 0 8px;
  height: 100%;
}

.link{
  padding: 8px;
  display: block;
  color: var(--TEXT-Base-Light-Primary);
  text-decoration: none;
  height: 100%;
}

.detail {
  color: var(--TEXT-Base-Light-Primary);
}

.detailImage{
  width: 100%;
  height: 160px;
  aspect-ratio: 1/1.4;
  position: relative;

  .detailImageWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow:hidden;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-image:
        linear-gradient(
          180deg, rgba(0, 0, 0, 0) 43.32%,
          rgba(0, 0, 0, 0.7) 72.33%
        );
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    img {
      max-width: none;
      width: auto;
      height: 100%;
    }
  }
}
.detailTitle{
  margin-top: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.detailText{
  margin-top: 4px;
  color: var(--TEXT-Base-Light-Secondar);
  font-size: 12px;
  line-height: 17px;
}
.stationContainer {
  display: flex;
  white-space: nowrap;

  .stationName {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.shopRating{
  margin-top: 4px;
}
.campaignLabels{
  margin-top: 4px;
}
