.state {
  flex-shrink: 0;
  padding: 4px;
  border-radius: 4px;
  font-size: 9px;
  font-weight: bold;


  &.pending {
    background-color: var(--BG-Tag-Request);
    color: var(--TEXT-Tag-Request);
  }
  
  &.confirmed {
    background-color: var(--BG-Tag-Reserve);
    color: var(--TEXT-Tag-Reserve);
  }
  
  &.rejected {
    background-color: var(--BG-Tag-Done);
    color:  var(--TEXT-Tag-Done);
  }
  
  &.canceled {
    background-color: var(--BG-Tag-Done);
    color:  var(--TEXT-Tag-Done);
  }
  
  &.visited {
    background-color: var(--BG-Tag-Done);
    color:  var(--TEXT-Tag-Done);
  }
}
