.shopCardList {
  padding-right: 4.26%;
  padding-left: 4.26%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  column-gap: 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.shopCardSlider{
  div[class^="slick-track"]{
    display: flex;

    div[class^="slick-slide"]{
      height: auto !important;

      > div{
        height: 100%;
      }
    }
  }
}

.slideArrow {
  margin: auto;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 19px); /* minus title/description height & margin */
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (--more-than-tablet) {
    width: 48px;
    height: 48px;
    transition: opacity 0.3s;
  }

  &:hover {
    @media (--more-than-tablet) {
      opacity: 0.8;
    }
  }

  img {
    width: 24px;
    height: 24px;

    @media (--more-than-tablet) {
      width: 48px;
      height: 48px;
    }
  }

  &.disabled {
    @media (--more-than-tablet) {
      opacity: 0.5;
      cursor: auto;
    }
  }
}

.slideArrowPrev {
  left: 8px;
  transform-origin: center;
  transform: translate3d(0, -50%, 0) rotate(180deg);

  @media (--more-than-tablet) {
    left: -24px;
  }
}

.slideArrowNext {
  right: 8px;
  transform-origin: center;
  transform: translate3d(0, -50%, 0);

  @media (--more-than-tablet) {
    right: -24px;
  }
}

.moreCard{
  height: auto;

  @media (--more-than-tablet) {
    padding: 0 8px;
    height: 100%;
  }
}

.moreButton{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #333333;
  border-radius: 8px;
  width: 135px;
  height: 100%;
  text-decoration: none;

  @media (--more-than-tablet) {
    margin-right: 0;
    max-width: 177px;
    width: 58%;
  }

  .moreButtonIcon{
    width: 63px;
    height: 63px;
  }

  span{
    margin-top: 4px;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    position: relative;
  }
}
