.articleCard{
  display: flex;
  background: var(--BG-Base-Dark-Tertiary);
  border-radius: 4px;
  text-decoration: none;
  color:var(--TEXT-Base-Light-Primary);
  overflow: hidden;
  font-family: var(--font-noto);
}

.portraitCard{
  @media (--more-than-tablet) {
    flex-direction: column;

    .articleThumbnail{
      width: 100%;
      height: 100%;
    }
    .articleInfo{
      padding: 16px;
    }
  }
}

.landscapeSmall{
  width: 272px;
  .articleTitle{
    font-size: 13px;
  }
  .articleThumbnail{
    width: 88px;
    height: 88px;
  }
}

.articleThumbnail{
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.articleInfo{
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
}

.articleTitle{
  font-size: 17px;
  font-weight: 700;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.articleCategory{
  display: flex;
  align-items: center;
  column-gap: 8px;

  .date{
    font-size: 12px;
    color: var(--TEXT-Base-Light-Secondary);
  }

  .team{
    padding: 3px 6px;
    background-color: var(--BG-Tag-Dark);
    color:var(--TEXT-Base-Light-Primary);
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    border-radius: 4px;
    flex: none;
    display: flex;
    align-items: center;

    .teamName{
      line-height: 1;
    }

    .teamLogo{
      width: 16px;
    }
  }
}
