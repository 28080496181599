.popularArea {
  margin-top: 20px;
}

.popularAreaList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;

  li {
    flex-shrink: 0;
    font-size: 14px;
    line-height: 1;
  }

  a {
    display: inline-block;
    padding: 7px;
    border: 1px solid  var(--STROKE-Tag-Primary);
    border-radius: 4px;
    color: var(--TEXT-Tag-Light);
    text-decoration: none;
  }
}

