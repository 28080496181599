.articleCard {
  display: flex;
  background: #3D3D3D;
  border-radius: 4px;
  overflow: hidden;
}

.articleThumbnail {
  width: 120px;
  height: 120px;
  aspect-ratio: 1/1;
}

.articleInfo {
  flex: 1;
  padding: 18px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
}

.articleTitle {
  .text {
    margin-top: 8px;
    max-width: 104px;

    @media (--more-than-tablet) {
      max-width: 396px;
    }
  }
}

.articleCategory {
  .text {
    max-width: 104px;
  }
}
