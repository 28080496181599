.daznLogo{
  width: 32px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.match {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
  z-index: 10;
}

.matchGenreLabel{
  margin-bottom: 8px;
  padding: 4px;
  width: fit-content;
  background: linear-gradient(119.46deg, #DF18FF 13.41%, #FF0000 92.31%);
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
}
.matchTournamentName{
  font-size: 10px;
  line-height: 15px
}

.matchTeam{
  display: flex;
  align-items: center;
  column-gap: 4px;

  .teamIcon{
    display: block;
    width: 20px;

    @media (--more-than-tablet) {
      width: 32px;
    }
  }

  .teamName{
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 12px;

    @media (--more-than-tablet) {
      font-size: 14px;
    }
  }

  .vs{
    font-size: 10px;
    line-height: 14px;
  }
}

.matchDate {
  font-size: 10px;
  line-height: 15px;

  @media (--more-than-tablet) {
    font-size: 12px;
  }
}
