.heading {
  font-family: var(--font-noto);
  margin-bottom: 24px;
}

.logoDazn {
  margin: 16px 0 0;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.logoDazn:before {
  margin-right: 12px;
  content: "";
  width: 36px;
  height: 36px;
  background: transparent url(/img/logo_dazn.svg) no-repeat top left;
  background-size: cover;
}

.section {
  padding: 20px 0;

  @media (--more-than-tablet) {
    padding: 40px 0;
  }
}

.categoryTab {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (--more-than-tablet) {
    max-width: 944px;
    margin: 0 auto 12px;
  }
}

.categoryTabItem {
  width: 100%;
  height: 49px;
  border: none;
  background-color: transparent;
  color: var(--TEXT-Base-Light-Tertiary);

  @media (--more-than-tablet) {
    background-color: var(--BG-Tab-Dark-Primary);
  }

  .name {
    display: block;
    line-height: 1.5;
    font-size: 16 px;
    white-space: pre-line;

    @media (--more-than-tablet) {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  &.isActive {
    background-color: transparent;
    border-bottom: 2px solid var(--STROKE-Tab-Primary);
    color: var(--TEXT-Tab-Primary);
  }
}

.categoryInner{
  display: none;

  &.isActive{
    display: block;
  }

  .logoDazn{
    margin-top: 12px;
  }
}

.teamFilter{
  display: flex;
  gap: 8px;
}

.teamFilterItem{
  padding: 5px 16px;
  display: block;
  border-radius: 50px;
  background-color: var(--BG-Base-Dark-Secondary);
  white-space: break-spaces;
  line-height: 13.2px;
  border: none;

  .tournament{
    color: var(--TEXT-Base-Light-Primary);
    font-size: 12px;
  }

  &.isActive{
    background-color: var(--BG-Tab-Dark-Secondary);

    .tournament{
      color: var(--TEXT-Tab-Primary);
    }
  }
}

.container {
  @media (--more-than-tablet) {
    max-width: 944px;
  }
}
