.favoriteTeamAreaTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.topPage{
    margin-bottom: 8px;
  }
  &.myPage{
    margin-bottom: 20px;
  }
}
.editButton{
  padding: 0;
  appearance: none;
  outline: none;
  background: none;
  border: none;
  color: var(--TEXT-Link-Primary);

  p{
    text-decoration: underline;
    font-family: var(--font-noto);
  }
}
.addButton{
  padding: 0;
  appearance: none;
  outline: none;
  background: transparent url(/img/icon_add_plus.svg) no-repeat center / 24px 24px;
  width: 64px;
  height: 64px;
  border: 1px solid var(--STROKE-Base-Colored-Primary);
  border-radius: 50px;
}
.favoriteTeamList{
  margin: 0 -4.3%;
  padding: 0 4.3%;
  display: flex;
  align-items: center;
  gap: 12px;
  overflow-y: auto;

  &.myPage {
    &.invisible {
      visibility: hidden;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (--more-than-tablet) {
    margin: 0;
    padding: 0 0 0 6px;
  }
}
.favoriteTeamListItem{
  display: block;
  width: 64px;
  text-align: center;
  text-decoration: none;
}
.favoriteTeamListItemLogo{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: var(--BG-Base-Dark-Tertiary);
  border-radius: 50px;
}
.favoriteTeamListItemLogo img{
  width: 48px;
}
.favoriteTeamListItemText{
  margin: 4px -6px 0 -6px;
  font-family: var(--font-noto);
  color: var(--TEXT-Base-Light-Primary);
}

.contentTitle{
  color: var(--TEXT-Base-Light-Tertiary);
}
