.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 21px;
}

.star {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    height: 17px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 17px;
  }

  &:before {
    width: 88px;
    background-image: url(/img/icon_rating.svg);
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    background-image: url(/img/icon_rating_yellow.svg);
    overflow: hidden;
  }
}

.star0 {
  &_1 {
    &:after {
      width: calc(16px - 14px);
    }
  }

  &_2 {
    &:after {
      width: calc(16px - 13px);
    }
  }

  &_3 {
    &:after {
      width: calc(16px - 11px);
    }
  }

  &_4 {
    &:after {
      width: calc(16px - 10px);
    }
  }

  &_5 {
    &:after {
      width: calc(16px - 8px);
    }
  }

  &_6 {
    &:after {
      width: calc(16px - 6px);
    }
  }

  &_7 {
    &:after {
      width: calc(16px - 5px);
    }
  }

  &_8 {
    &:after {
      width: calc(16px - 3px);
    }
  }

  &_9 {
    &:after {
      width: calc(16px - 2px);
    }
  }
}

.star1 {
  &:after {
    width: 16px;
  }

  &_1 {
    &:after {
      width: calc(34px - 14px);
    }
  }

  &_2 {
    &:after {
      width: calc(34px - 13px);
    }
  }

  &_3 {
    &:after {
      width: calc(34px - 11px);
    }
  }

  &_4 {
    &:after {
      width: calc(34px - 10px);
    }
  }

  &_5 {
    &:after {
      width: calc(34px - 8px);
    }
  }

  &_6 {
    &:after {
      width: calc(34px - 6px);
    }
  }

  &_7 {
    &:after {
      width: calc(34px - 5px);
    }
  }

  &_8 {
    &:after {
      width: calc(34px - 3px);
    }
  }

  &_9 {
    &:after {
      width: calc(34px - 2px);
    }
  }
}

.star2 {
  &:after {
    width: 34px;
  }

  &_1 {
    &:after {
      width: calc(52px - 14px);
    }
  }

  &_2 {
    &:after {
      width: calc(52px - 13px);
    }
  }

  &_3 {
    &:after {
      width: calc(52px - 11px);
    }
  }

  &_4 {
    &:after {
      width: calc(52px - 10px);
    }
  }

  &_5 {
    &:after {
      width: calc(52px - 8px);
    }
  }

  &_6 {
    &:after {
      width: calc(52px - 6px);
    }
  }

  &_7 {
    &:after {
      width: calc(52px - 5px);
    }
  }

  &_8 {
    &:after {
      width: calc(52px - 3px);
    }
  }

  &_9 {
    &:after {
      width: calc(52px - 2px);
    }
  }
}

.star3 {
  &:after {
    width: 52px;
  }

  &_1 {
    &:after {
      width: calc(70px - 14px);
    }
  }

  &_2 {
    &:after {
      width: calc(70px - 13px);
    }
  }

  &_3 {
    &:after {
      width: calc(70px - 11px);
    }
  }

  &_4 {
    &:after {
      width: calc(70px - 10px);
    }
  }

  &_5 {
    &:after {
      width: calc(70px - 8px);
    }
  }

  &_6 {
    &:after {
      width: calc(70px - 6px);
    }
  }

  &_7 {
    &:after {
      width: calc(70px - 5px);
    }
  }

  &_8 {
    &:after {
      width: calc(70px - 3px);
    }
  }

  &_9 {
    &:after {
      width: calc(70px - 2px);
    }
  }
}

.star4 {
  &:after {
    width: 70px;
  }

  &_1 {
    &:after {
      width: calc(88px - 14px);
    }
  }

  &_2 {
    &:after {
      width: calc(88px - 13px);
    }
  }

  &_3 {
    &:after {
      width: calc(88px - 11px);
    }
  }

  &_4 {
    &:after {
      width: calc(88px - 10px);
    }
  }

  &_5 {
    &:after {
      width: calc(88px - 8px);
    }
  }

  &_6 {
    &:after {
      width: calc(88px - 6px);
    }
  }

  &_7 {
    &:after {
      width: calc(88px - 5px);
    }
  }

  &_8 {
    &:after {
      width: calc(88px - 3px);
    }
  }

  &_9 {
    &:after {
      width: calc(88px - 2px);
    }
  }
}

.star5 {
  &:after {
    width: 88px;
  }
}

.rate {
  margin-left: 4px;
  font-size: 14px;
  line-height: 1.5;
}

.rateNumber {
  color: var(--TEXT-Base-Light-Primary);
  font-weight: bold;
}

.rateCount {
  margin-left: 4px;

  &.small {
    font-size: 10px;
    vertical-align: text-top;
    color: var(--TEXT-Base-Light-Secondary);
  }
}
