.slideItems {
  cursor: pointer;
}

.keyVisualImage {
  position: relative;
  font-size: 0;
  min-height: 184px;

  &.single {
    @media (--more-than-tablet) {
      max-width: 960px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.slideDot {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  margin: 0 auto;
  text-align: center;
  font-size: 0;

  li {
    display: inline-block;
    margin: 0 4px;
  }

  li button {
    display: block;
    width: 6px;
    height: 6px;
    padding: 0;
    background-color: #c4c4c4;
    border: none;
    border-radius: 50%;
  }

  :global(li.slick-active) button {
    background-color: var(--color-yellow);
  }
}
