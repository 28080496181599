.container {
  max-width: 944px;
}

.title {
  max-width: 160px;
}

.recommendList {
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 4.26%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 16px;

  @media (--more-than-tablet) {
    overflow: visible;
    width: calc(91.46667% + 16px);
    max-width: 960px;
    padding: 0 8px;
    margin: 16px auto 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .list {
    display: flex;

    @media (--more-than-tablet) {
      gap: 0 16px;
    }

    li {
      min-width: 240px;

      @media (--more-than-tablet) {
        min-width: none;
        flex: 1;
      }
    }
  }
}

