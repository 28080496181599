.list {
  display: flex;
  flex-wrap: wrap;
}

.item {
  position: relative;
  width: 31.19534%;
  border-radius: 4px;
  overflow: hidden;

  @media (--more-than-tablet) {
    width: 32.2%;
    height: 100px;
  }

  &:not(:nth-child(3n)) {
    margin-right: 3.207%;

    @media (--more-than-tablet) {
      margin-right: 1.6%;
    }
  }

  &:nth-child(n+4) {
    margin-top: 12px;
  }
}

.link {
  display: block;
  color: var(--TEXT-Base-Light-Primary);
  text-decoration: none;

  img {
    @media (--more-than-tablet) {
      height: 100px;
      object-fit: cover;
    }
  }
}

.name {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 18px;
  line-height: 1.4;
  font-weight: bold;
  text-align: center;
  white-space: pre-line;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 2px 12px rgba(0, 0, 0 , 0.5);

  @media (--more-than-tablet) {
    white-space: nowrap;
  }
}
