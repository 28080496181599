.note {
  padding-top: 12px;
  font-size: 12px;
  color: var(--TEXT-Base-Light-Secondary);
  line-height: 1.5;

  @media (--more-than-tablet) {
    font-size: 14px;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(/img/icon/alert_exclamation_white.svg) no-repeat center center;
    background-size: contain;
    vertical-align: middle;
    margin-right: 4px;
  }
}
