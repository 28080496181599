.wrapper {
  @media (--more-than-tablet) {
    display: flex;
    flex-wrap: wrap;
  }
}

.item {
  @media (--more-than-tablet) {
    width: 32.2%;
    margin-right: 1.6%;
    margin-bottom: 0;
  }

  &:nth-child(n+4) {
    margin-top: 16px;
  }

  &:nth-child(3n) {
    @media (--more-than-tablet) {
      margin-right: 0;
    }
  }
}
