.slide {
  position: relative;
}

.slideItems{
  img{
    @media (--more-than-tablet) {
      max-height: 400px;
      object-fit: cover;
    }
  }

  &.isOverlayPhotoOptions{
    cursor: pointer;

    @media (--more-than-tablet) {
      &:hover{
        opacity: 0.8;
        transition: opacity 0.3s;
      }
    }
  }
}

.slideArrow {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 10;
  display: none;
  justify-content: flex-end;
  align-items: center;

  @media (--more-than-tablet) {
    width: 48px;
    height: 48px;
    transition: opacity 0.3s;
    display: flex;
  }

  &:hover {
    @media (--more-than-tablet) {
      opacity: 0.8;
    }
  }

  img {
    width: 24px;
    height: 24px;

    @media (--more-than-tablet) {
      width: 48px;
      height: 48px;
    }
  }
}

.slideArrowPrev {
  transform: rotate(180deg);

  @media (--more-than-tablet) {
    left: 32px;
  }
}

.slideArrowNext {
  @media (--more-than-tablet) {
    right: 32px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.slideCount {
  padding: 4px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 11px;
  line-height: 12px;

  @media (--more-than-tablet) {
    bottom: 16px;
    right: 34.5%;
    font-size: 12px;
    line-height: 14px;
  }
}
