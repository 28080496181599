.link {
  padding: 0 8px 0 0;

  @media (--more-than-tablet) {
    padding: 0;
  }
}

.detailImage {
  position: relative;
  margin-bottom: 16px;
}

.detailImageWrap {
  @media (--more-than-tablet) {
    position: relative;
    padding-top: 51.845%;
  }

  .image {
    min-height: 135px;
    object-fit: cover;

    @media (--more-than-tablet) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
    }
  }
}

.detailTitle {
  margin-bottom: 4px;

  .text {
    max-width: 160px;
  }
}

.detailText {
  .text {
    max-width: 120px;
  }
}
