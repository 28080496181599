.matchBox {
  + .matchBox {
    margin-top: 32px;

    @media (--more-than-tablet) {
      margin-top: 52px;
    }
  }
}

.matchBoxTitle {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 12px;
}

.matchBoxLink {
  margin-top: 12px;
  font-size: 15px;
  text-align: right;

  @media (--more-than-tablet) {
    font-size: 14px;
  }

  a {
    color: var(--TEXT-Base-Light-Secondary);
  }
}
