.arrow {
  margin: auto;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
}

.prev {
  left: 8px;
  transform: rotate(180deg);
}

.next {
  right: 8px;
}

.disabled {
  opacity: 0.5;
  cursor: auto;
}

.wrapper {
  height: calc(100vh - 160px);

  @media (--more-than-tablet) {
    max-height: 720px;
  }
}
